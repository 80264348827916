import { DashboardSideNav, firebase } from "@project/shared";
import { Layout } from "antd";
import { useRouter } from "next/router";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AuthContext } from "../../../utils/AuthContext";

const { Sider } = Layout;

const SiderWrapper = styled(Sider)`
  min-width: 256px !important;
  max-width: 256px !important;
  flex: 0 0 256px !important;
`;

const Sidebar = () => {
  const router = useRouter();
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  const handleSignOut = async () => {
    await firebase.auth().signOut();
    router.push("/login");
  };
  const menus = [
    {
      className: router.pathname.startsWith("/company") && "active",
      key: "1",
      onClick: () => router.push("/company"),
      name: t("Company"),
    },
    {
      className: router.pathname.startsWith("/sb-members") && "active",
      key: "2",
      name: t("sb Members"),
      onClick: () => router.push("/sb-members"),
    },
    {
      className: router.pathname.startsWith("/jobs") && "active",
      key: "3",
      name: t("Jobs"),
      onClick: () => router.push("/jobs"),
    },
    {
      className: router.pathname.startsWith("/entries") && "active",
      key: "4",
      name: t("Entry"),
      onClick: () => router.push("/entries"),
    },
    {
      className: router.pathname.startsWith("/scouts") && "active",
      key: "5",
      name: t("Scout"),
      onClick: () => router.push("/scouts"),
    },
    {
      className: router.pathname.startsWith("/referrals") && "active",
      key: "6",
      name: t("Referral"),
      onClick: () => router.push("/referrals"),
    },
    {
      className: router.pathname.startsWith("/message") && "active",
      key: "7",
      name: t("Message"),
      onClick: () => router.push("/message"),
    },
    {
      className: router.pathname.startsWith("/block-list") && "active",
      key: "8",
      name: t("Block list"),
      onClick: () => router.push("/block-list"),
    },
    {
      className: router.pathname.startsWith("/direct-message") && "active",
      key: "9",
      name: t("Direct Message"),
      onClick: () => router.push("/direct-message"),
    },
    {
      key: "10",
      onClick: handleSignOut,
      name: t("Logout"),
    },
  ];
  if (!user) {
    return <></>;
  }
  return (
    <SiderWrapper trigger={null} collapsed={false}>
      <DashboardSideNav menu={menus} href="/company" />
    </SiderWrapper>
  );
};

export { Sidebar };
