import * as Sentry from "@sentry/node";
import { AppProps } from "next/app";
import {
  Alert,
  analytics,
  firebase,
  TopProgressBar,
  i18n,
  GlobalStyle,
} from "@project/shared";
import { useEffect, useState } from "react";
import "antd/dist/antd.css";
import "../utils/css-imports";
import { Layout, ConfigProvider } from "antd";
import { useRouter } from "next/router";
import "../utils/globalStyles.css";
import styled from "styled-components";
import Head from "next/head";
import { AuthProvider } from "../utils/AuthContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { useTranslation } from "react-i18next";
import { Sidebar } from "../components/molecules";

import jaJP from "antd/lib/locale/ja_JP";
import enUS from "antd/lib/locale/en_US";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    // enabled: process.env.NODE_ENV !== "development",
    environment: `admin-${process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT}`,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

const LayoutWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  background-color: #f0f2f5;
  & .ant-layout-header {
    height: 54px;
    padding: 0 50px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 64px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }
  & .ant-layout-content {
    padding: 30px;
  }
`;

export { Layout };

const queryClient = new QueryClient();

const MyApp = ({ Component, pageProps }: AppProps) => {
  const routers = useRouter();
  const [user, setUser] = useState(null as firebase.User | null);

  const { t } = useTranslation();
  const restrictedRoute = [
    "/login",
    "/",
    "/forgot-password",
    "/forgot-password/done",
  ];

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const logEvent = (url: string) => {
        analytics().setCurrentScreen(url);
        analytics().logEvent("screen_view", {
          screen_name: url,
          app_name: "Sidebizz-Admin",
        });
      };

      routers.events.on("routeChangeComplete", (url) => {
        window.scrollTo(0, 0);
        logEvent(url);
      });

      logEvent(window.location.pathname);
      return () => {
        routers.events.off("routeChangeComplete", logEvent);
      };
    }
  }, []);

  // useEffect(() => {
  //   firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
  // }, []);

  const initialLoad = () => {
    firebase.auth().onAuthStateChanged(async (user) => {
      queryClient.setDefaultOptions({
        queries: {
          enabled: user !== null,
        },
      });
      try {
        if (user !== null) {
          const idToken = await user!.getIdTokenResult();

          if (idToken.claims.isAdmin === true) {
            setUser(user);
          } else {
            firebase.auth().signOut();

            setUser(null);
            Alert({
              type: "error",
              message: t("Error occured"),
              description: t("Unauthorized User"),
            });
            window.location.assign("https://www.sidebizz.net/");
          }
        }
      } catch (error) {
        Alert({ type: "error", message: t("Error occured") });
      }
    });
  };
  useEffect(() => {
    initialLoad();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Head>
        <title>Admin | side bizz</title>
      </Head>
      <GlobalStyle />
      <TopProgressBar />
      <ConfigProvider locale={i18n.language === "en-US" ? enUS : jaJP}>
        <AuthProvider>
          <Layout>
            {!restrictedRoute.includes(routers.pathname) &&
              user &&
              !routers.pathname.startsWith("/jobs/details") && <Sidebar />}

            <LayoutWrapper>
              <Component {...pageProps} />
            </LayoutWrapper>
          </Layout>
        </AuthProvider>
      </ConfigProvider>
    </QueryClientProvider>
  );
};

export default MyApp;
